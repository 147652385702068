/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import './style.css';

import loadComponents from '../Loadable';

const Portal = loadComponents('portal');
const LightboxButton = loadComponents('lightbox-button');
const Backdrop = loadComponents('lightbox-backdrop');

export default function LightboxWrapper(props) {
  const { showLightbox, closeLightbox, children, noLoader } = props;
  return (
    showLightbox && (
      <Portal portal="lightbox">
        <Backdrop noLoader={noLoader}>
          <LightboxButton onClick={closeLightbox} close>
            Close
          </LightboxButton>
          <div style={modalStyle}>{children}</div>
          <button
            type="button"
            className="hidden"
            onClick={() => closeLightbox && closeLightbox()}
            style={buttonStyle}
          />
        </Backdrop>
      </Portal>
    )
  );
}

const modalStyle = {
  position: `relative`,
  width: `1600px`,
  maxWidth: `85%`,
  maxHeight: `85%`,
  zIndex: 600,
  animation: `modal 0.3s forwards`,
  animationDelay: `200ms`,
  opacity: 0,
};

const buttonStyle = {
  position: `absolute`,
  background: `transparent`,
  border: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 550,
};
